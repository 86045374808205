import React, { useState } from "react";
import Empty from "../../components/empty";
import { useCustomerContext } from "../../provider/CustomerContext";
import MobileProducts from "./mobile-products";
import useMobile from "../../hooks/useMobile";
import AffiliateTable from "./table";
import { Tooltip } from "@sellix/shared/components";
import { CopyIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../provider/AuthContext.ts";
import UpdateCustomerAffiliateLinkModal from "./update-customer-affiliate-link.tsx";

import "./index.css";


export const EditIcon = ({
  width = 20,
  height = 20
}) => <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.33325 16.6643H6.66659L15.4166 7.91431C15.8586 7.47229 16.1069 6.87277 16.1069 6.24765C16.1069 5.62253 15.8586 5.02301 15.4166 4.58098C14.9746 4.13895 14.375 3.89063 13.7499 3.89063C13.1248 3.89062 12.5253 4.13895 12.0833 4.58098L3.33325 13.331V16.6643Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M11.25 5.41406L14.5833 8.7474" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


const Affiliate = () => {

  const { customerPortalDomain } = useAuthContext();
  const { affiliatedProducts, customerInfo } = useCustomerContext();
  const { t } = useTranslation('translation', { keyPrefix: "customer.affiliates" });
  const isMobile = useMobile();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const isEmpty: boolean = !affiliatedProducts.length;

  if(!customerInfo) {
    return null
  }

  if(isEmpty) {
    return <Empty isProducts />
  }

  const isApproved = customerInfo.affiliate_custom_link_status === "APPROVED";

  const affiliateLinkHost = `${customerPortalDomain ? 'https://' : ''}${customerPortalDomain ? customerPortalDomain : window.location.host}`;
  const affiliateLinkPath = isApproved ? `/a/${customerInfo.affiliate_custom_link}` : `?rcid=${customerInfo.id}`;
  const affiliateLink = `${affiliateLinkHost}${affiliateLinkPath}`;

  const affiliateCode = isApproved ? customerInfo.affiliate_custom_link : customerInfo.id;

  return <div className="affiliate-screen">

    {customerInfo.shop.affiliate_revenue_active ? <div className="affiliate-top-link">

      <div className="flex flex-col gap-4 md:flex-col max-w-full">
        <div className="w-full md:w-1/2">
          <div className="affiliate-top-link-title mb-2">
            {t("storeAffiliateLink")}
          </div>

          <Tooltip noArrow tooltipText={t("copyShopLink")} successText={t("copied")} copiedText={affiliateLink} name="referral-link">
            <div className="affiliate-top-link-input">
              <span>{affiliateLink}</span> <CopyIcon color="var(--purple6)"/>
            </div>
          </Tooltip>

        </div>

      </div>

      <div className="flex flex-col gap-4 md:flex-col max-w-full">
        <div className="w-full md:w-1/2">
          <div className="affiliate-top-link-title mb-2">
            {t("storeAffiliateCode")}
          </div>

          <Tooltip noArrow tooltipText={"Copy Code"} successText={t("copied")} copiedText={affiliateCode}
            name="referral-code">
            <div className="affiliate-top-link-input">
              <span>{affiliateCode}</span> <CopyIcon color="var(--purple6)"/>
            </div>
          </Tooltip>

          {customerInfo.shop.affiliate_custom_link_active ? <div className="mt-1">
            <Tooltip noArrow name="affiliate-link" tooltipText={<div>
              <div>
                {t("linkTooltip")}
              </div>
            </div>}>
              <div className="affiliate-top-link-subtitle flex gap-2 items-center">
                {t("personalizeLink")}
                <div onClick={() => setIsEditOpen(true)}>
                  <EditIcon/>
                </div>
              </div>
            </Tooltip>
            <UpdateCustomerAffiliateLinkModal
              isOpen={isEditOpen}
              close={() => setIsEditOpen(false)}
            />
          </div> : null}

        </div>
      </div>

    </div> : ""}

    {customerInfo.shop.affiliate_revenue_active && customerInfo.shop.affiliate_customer_discount_active ?
      <div className="affiliate-top-link">
        <div className="affiliate-top-link-title">
          {t("discountCodeLinkMessage")}
            - <b>{+customerInfo.customer.affiliate_customer_discount_percent ? customerInfo.customer.affiliate_customer_discount_percent : customerInfo.shop.affiliate_customer_discount_percent}% off</b>
        </div>
      </div> : ""}

    {isMobile ?
      <MobileProducts data={affiliatedProducts}/> :
      <AffiliateTable isApproved={isApproved} data={affiliatedProducts} customerInfo={customerInfo}/>
    }
  </div>
}

export default Affiliate;
