import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../provider/AuthContext";
import { useCustomerContext } from "../../../provider/CustomerContext.tsx";
import { Spin, Button } from "@sellix/shared/components";
import Modal from "../../../components/modal";
import useSubmitPayment from "./useSubmit.ts";
import useTaxForm from "./useTaxForm.ts";

import "./index.css";


const TopUpModal = ({ isOpen, close }: { isOpen: boolean, close: () => void }) => {

  const { customerProfile } = useAuthContext();
  const { customerInfo, shopInfo, refetchCustomerInfo } = useCustomerContext();

  const iframeRef: LegacyRef<HTMLIFrameElement> | undefined = useRef(null);
  const refCaptcha = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('translation', { keyPrefix: 'customer.profile' });

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [amount, setAmount] = useState<any>(undefined);
  const [src, setSrc] = useState<string>("");
  const currentMode = localStorage.getItem("theme") || "light";

  // @ts-ignore
  const taxForm = useTaxForm(shopInfo?.shop);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!event.origin.includes("sellix")) {
        return;
      }

      if (event.data === 'close-embed') {
        setSrc("");
        close();
        setInvoiceLoading(false)
        refetchCustomerInfo();
        setAmount(undefined);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if(isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isOpen]);

  const handleSubmitPayment = useSubmitPayment({
    // @ts-ignore
    shopName: shopInfo?.shop.name,
    // @ts-ignore
    email: customerProfile?.email,
    setSending: setInvoiceLoading,
    onSuccessComponent: (invoice: any) => {
      if (process.env.NODE_ENV === "development") {
        setSrc(`http://embed.local-test-sellix.com:3000/invoice/${invoice?.uniqid}`)
      } else if (process.env.NODE_ENV === "staging") {
        setSrc(`https://embed.sellix-staging.io/invoice/${invoice?.uniqid}`)
      } else {
        setSrc(`https://embed.sellix.io/invoice/${invoice?.uniqid}`)
      }

      close()
      setAmount(undefined)
    },
    pay_what_you_want_price: amount,
    currency: customerInfo?.affiliate_revenue_currency || "USD",
    refCaptcha,
    isTopUp: true
  });


  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !(invoiceLoading || parseFloat(amount) <= 0 || !amount)) {
      handleSubmitPayment();
    }
  };

  return <>

    <div ref={refCaptcha} className="auth-content-captcha"/>

    {src ? <iframe ref={iframeRef} src={src} id="site-iframe" onLoad={() => setInvoiceLoading(false)} className="block border-0 fixed top-0 left-0 w-full h-full z-[2000]" style={{ backgroundColor: "rgba(0, 0, 0, 0.46)",  backdropFilter: "blur(3px)" }} /> : null}

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={close}>
      <div className="p-8 pb-6 border-b-0">
        <div className="new-modal-title">
          {t('topUp.modal.title')}
        </div>
        <div className="new-modal-description">
          {t('topUp.modal.subtitle')}
        </div>
      </div>
      <form className="p-8 pt-0 pb-0" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="" className="sellix-label">{t('topUp.modal.label')}</label>
          <div className="relative">
            <input ref={inputRef} type="number" autoFocus onKeyDown={handleKeyDown}
              className="sellix-input !rounded-r-[0px]" placeholder="9.99" value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{ width: "calc(100% - 70px)" }}
            />
            <div
              className="flex text-center items-center justify-center !rounded-r-lg w-[70px] absolute top-0 right-0 h-[40px] font-medium text-sm"
              style={{
                color: "var(--black9)",
                background: "var(--secondary-background-color)",
              }}>
              {customerInfo?.affiliate_revenue_currency}
            </div>
          </div>
          {taxForm.taxAmount ?
            <div className="sellix-note mt-2">{t('topUp.modal.note')} ({taxForm.taxAmount})</div> : null}
        </div>
      </form>
      <div className="justify-between border-t-0 p-8 pt-6">
        <Button onClick={() => handleSubmitPayment()} className="w-full" size="extra-large"
          disabled={invoiceLoading || parseFloat(amount) <= 0 || !amount}>
          {invoiceLoading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : t('topUp.modal.button')}
        </Button>
      </div>
    </Modal>

  </>
}

export default TopUpModal;
