import React, { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { PayPalConnectVault } from "@sellix/shared/checkout/payments";
import { toastAlert } from "@sellix/shared/components";
import config from "../../../utils/config.tsx";
import { useAuthContext } from "../../../provider/AuthContext.ts";
import { paypalCreateVaultSetupToken, paypalSaveVaultSetupToken } from "../actions.ts";

interface Props {
  onGoBack: () => void
}

const PayPalPaymentMethod: React.FC<Props> = ({ onGoBack }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { shopId, customerProfile } = useAuthContext();
  const currentMode = localStorage.getItem("theme") || "light";

  const onMessage = useCallback((type: string, message: string) => {
    toastAlert(type as "success" | "error" | "warning" | "info", message);
  }, []);

  const createVaultSetupToken = useCallback(async(shopId: string | number, customerEmail: string) => {
    if (!executeRecaptcha) {
      return;
    }
    const captcha = await executeRecaptcha("createCode");
    return await paypalCreateVaultSetupToken(shopId, customerEmail, captcha);
  }, [executeRecaptcha]);

  const saveVaultSetupToken = useCallback(async(shopId: string | number, customerEmail: string, tokenPayload: Record<string, any>) => {
    if (!executeRecaptcha) {
      return;
    }
    const captcha = await executeRecaptcha("createCode");
    return await paypalSaveVaultSetupToken(
      shopId, customerEmail, tokenPayload, captcha
    )
  }, [executeRecaptcha]);

  const onConnect = useCallback(async() => onGoBack(), []);

  if (!shopId || !customerProfile?.email) {
    return null;
  }

  return (
    <PayPalConnectVault
      isDark={currentMode === "dark"}
      paypalClientId={config.PAYPAL.CLIENT_ID}
      shopId={`${shopId}`}
      customerEmail={customerProfile.email}
      paymentMethod={undefined}
      createVaultSetupToken={createVaultSetupToken}
      saveVaultSetupToken={saveVaultSetupToken}
      onConnect={onConnect}
      onMessage={onMessage}
    />
  );
}

export default PayPalPaymentMethod;