import { useEffect, useState } from "react";

const usePayPal = ({ config, shopInfo }) => {
  const payPalMerchantID = shopInfo.shop.paypal_merchant_id;

  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    if (!hasRendered) {
      console.log("This runs only on the first render!");
      setHasRendered(true);
    }
  }, [hasRendered]);

  useEffect(() => {
    if (payPalMerchantID && !window.paypal) {
      const script = document.createElement("script");
      const client = config.PAYPAL.CLIENT_ID;

      const url = new URL("https://www.paypal.com/sdk/js");
      url.searchParams.append("client-id", client);
      url.searchParams.append("merchant-id", payPalMerchantID);
      url.searchParams.append("components", "buttons,funding-eligibility");
      url.searchParams.append("currency", "USD");
      url.searchParams.append("commit", "false");
      url.searchParams.append("vault", "true");

      script.src = url.toString();

      script.async = true;
      script.id = "paypal-sdk";
      document.body.appendChild(script);
    }
  }, [payPalMerchantID, config.PAYPAL.CLIENT_ID]);
}

export default usePayPal;
