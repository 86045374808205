import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerContext } from "../../../provider/CustomerContext.tsx";
import { Spin, Button } from "@sellix/shared/components";
import Modal from "../../../components/modal";
import { redeemGiftCard as redeemGiftCardAction } from "../gift-cards/actions.ts";
import { toastAlert } from "@sellix/shared/components";

import "./index.css";


const RedeemModal = ({ isOpen, close, activeCode, getGiftCards }: { isOpen: boolean, close: () => void, activeCode?: string, getGiftCards?: () => void }) => {

  const { t } = useTranslation('translation', { keyPrefix: 'customer.profile' });

  const { refetchCustomerInfo } = useCustomerContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const [code, setCode] = useState<any>(activeCode);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const currentMode = localStorage.getItem("theme") || "light";
  const regexPattern = /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/;

  useEffect(() => {
    if(activeCode) {
      if (activeCode.includes("gc_") && activeCode.length === 22) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      setCode(activeCode);
    }
  }, [activeCode]);

  useEffect(() => {
    if(isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !(loading || !isValid || !code)) {
      void redeemGiftCard()
    }
  };

  const onChangeCode = (e: any) => {

    const code = e.target.value;
    setCode(code);

    if (code.includes("gc_") && code.length === 22) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  const redeemGiftCard = useCallback(async() => {
    try {
      setLoading(true);
      const res = await redeemGiftCardAction(code);

      if(res.status === 200) {
        void getGiftCards?.();
        refetchCustomerInfo();
        close();
        toastAlert("success", res.message || "");
      } else {
        throw res
      }

    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [code, getGiftCards]);


  return <>

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={close}>
      <div className="p-8 pb-6 border-b-0">
        <div className="new-modal-title">{t('giftCard.modal.title')}</div>
        <div className="new-modal-description">{t('giftCard.modal.subtitle')}</div>
      </div>
      <form className="p-8 pt-0 pb-0" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="" className="sellix-label">{t('giftCard.modal.label')}</label>
          <input ref={inputRef} type="text" autoFocus onKeyDown={handleKeyDown} className="sellix-input" placeholder="gc_xxxx-xxxx-xxxx-xxxx" value={code} onChange={onChangeCode} />
        </div>
      </form>
      <div className="justify-between border-t-0 p-8 pt-6">
        <Button onClick={redeemGiftCard} className="w-full" size="extra-large" disabled={loading || !isValid || !code}>
          {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : t('giftCard.modal.button')}
        </Button>
      </div>
    </Modal>

  </>
}

export default RedeemModal;
