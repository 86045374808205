import { IPaymentMethodsWrapper, IResponse } from '../../types/types.ts';
import customerApi from '../../utils/customer-api.ts';
import { formData } from "../../utils/config.tsx";

export const getPaymentMethods = async(): Promise<IResponse<IPaymentMethodsWrapper>> => {
  const res = await customerApi.get<never, IResponse<IPaymentMethodsWrapper>>(
    `customer/payment_methods`,
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const deletePaymentMethod = async(id: number): Promise<IResponse<undefined>> => {
  const res = await customerApi.post<never, IResponse<undefined>>(
    `customer/payment_methods/delete`,
    formData({ id }),
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const stripeCreateSetupIntent = async(
  shopId: string | number, customerEmail: string, captcha: string
): Promise<any> => {
  const res = await customerApi.post(`stripe/setup_intent`, formData({
    shop_id: shopId,
    customer_email: customerEmail,
    captcha,
  }));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
};

export const stripeRefreshSetupIntent = async(
  shopId: string | number, customerEmail: string, setupIntentId: string, captcha: string, title: string = ''
): Promise<any> => {
  const res = await customerApi.post(`stripe/setup_intent/refresh`, formData({
    shop_id: shopId,
    customer_email: customerEmail,
    setup_intent_id: setupIntentId,
    captcha,
    title,
  }));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
};

export const paypalCreateVaultSetupToken = async(
  shopId: string | number, customerEmail: string, captcha: string) => {
  const res = await customerApi.post(`paypal/vault/setup_tokens/init`, formData({
    shop_id: shopId,
    customer_email: customerEmail,
    captcha,
  }));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const paypalSaveVaultSetupToken = async(
  shopId: string | number, customerEmail: string, tokenPayload: Record<string, any>, captcha: string
) => {
  const res = await customerApi.post(`/paypal/vault/setup_tokens/save`, formData({
    shop_id: shopId,
    customer_email: customerEmail,
    token_payload: JSON.stringify(tokenPayload),
    captcha,
  }));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}