import customerApi from "../../utils/customer-api";
import { formData } from "../../utils/config.tsx";

export const updateCustomerAffiliateLink = async(
  custom_link: string,
  status: "UNSET" | "PENDING",
  customer_id: string,
  shop_id: string
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await customerApi.post(`/customer/link/request`, formData({
      shop_id,
      customer_id,
      custom_link,
      status,
    }));

    if(res.status === 200) {
      return res;
    } else {
      throw res;
    }
  } catch (error: any) {
    throw error;
  }
}

export const updateCustomerAffiliateCode = async(
  custom_code: string,
  status: "UNSET" | "PENDING",
  customer_id: string,
  shop_id: string
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await customerApi.post(`/customer/code/request`, formData({
      shop_id,
      customer_id,
      custom_code,
      status,
    }));

    if(res.status === 200) {
      return res;
    } else {
      throw res;
    }
  } catch (error: any) {
    throw error;
  }
}